import { Action } from '@ngrx/store';
import { PaginationHolder } from '@models/pagination.model';
import { ITillSessionModel, ITillSessionRequestModel } from '@models/tills/till-session.model';
import { ITillModel } from '@models/tills/till.model';
import { ITillExpectedAmountModel } from '@models/tills/till-expected-amount.model';

export enum ETillActions {
  ListTills = '[TILLS] List Tills',
  ListTillsSuccess = '[TILLS] List Tills Success',

  ListTillSessions = '[TILLS] List Till Sessions',
  ListTillSessionsSuccess = '[TILLS] List Till Sessions Success',

  GetTillSessionById = '[TILLS] Get Till Session By Id',
  GetTillSessionByIdSuccess = '[TILLS] Get Till Session By Id Success',

  StartTillSession = '[TILLS] Start Till Session',
  StartTillSessionSuccess = '[TILLS] Start Till Session Success',

  CloseTillSession = '[TILLS] Close Till Session',
  CloseTillSessionSuccess = '[TILLS] Close Till Session Success',

  FetchExpectedClosingAmounts = '[TILLS] Fetch Expected Closing Amounts',
  FetchExpectedClosingAmountsSuccess = '[TILLS] Fetch Expected Closing Amounts Success',

  CreateTill = '[TILLS] Create Till',
  CreateTillSuccess = '[TILLS] Create Till Success',

  SetSelectedTillRange = '[TILLS] Set Selected Till Range',
}

// --- List Tills

export class ListTills implements Action {
  public readonly type = ETillActions.ListTills;

  constructor() {}
}

export class ListTillsSuccess implements Action {
  public readonly type = ETillActions.ListTillsSuccess;

  constructor(public tills: PaginationHolder<ITillModel>) {}
}

// --- List Till Sessions

export class ListTillSessions implements Action {
  public readonly type = ETillActions.ListTillSessions;

  constructor(public tillId: string) {}
}

export class ListTillSessionsSuccess implements Action {
  public readonly type = ETillActions.ListTillSessionsSuccess;

  constructor(public tillSessions: PaginationHolder<ITillSessionModel>) {}
}

// --- Get Till Session By Id

export class GetTillSessionById implements Action {
  public readonly type = ETillActions.GetTillSessionById;

  constructor(
    public tillId: string,
    public sessionId: string
  ) {}
}

export class GetTillSessionByIdSuccess implements Action {
  public readonly type = ETillActions.GetTillSessionByIdSuccess;

  constructor(public tillSession: ITillSessionModel) {}
}

// --- Start Till Session

export class StartTillSession implements Action {
  public readonly type = ETillActions.StartTillSession;

  constructor(
    public tillId: string,
    public session: ITillSessionRequestModel
  ) {}
}

export class StartTillSessionSuccess implements Action {
  public readonly type = ETillActions.StartTillSessionSuccess;

  constructor() {}
}

// --- Close Till Session

export class CloseTillSession implements Action {
  public readonly type = ETillActions.CloseTillSession;

  constructor(
    public tillId: string,
    public sessionId: string,
    public session: ITillSessionRequestModel
  ) {}
}

export class CloseTillSessionSuccess implements Action {
  public readonly type = ETillActions.CloseTillSessionSuccess;

  constructor() {}
}

// --- Fetch Expected Closing Amount

export class FetchExpectedClosingAmounts implements Action {
  public readonly type = ETillActions.FetchExpectedClosingAmounts;

  constructor(
    public tillId: string,
    public sessionId: string
  ) {}
}

export class FetchExpectedClosingAmountsSuccess implements Action {
  public readonly type = ETillActions.FetchExpectedClosingAmountsSuccess;

  constructor(public expectedClosingAmounts: ITillExpectedAmountModel[]) {}
}

// --- Create Till

export class CreateTill implements Action {
  public readonly type = ETillActions.CreateTill;

  constructor(public name: string) {}
}

export class CreateTillSuccess implements Action {
  public readonly type = ETillActions.CreateTillSuccess;

  constructor() {}
}

// --- Set Selected Till Range

export class SetSelectedTillRange implements Action {
  public readonly type = ETillActions.SetSelectedTillRange;

  constructor(
    public from: string,
    public to: string
  ) {}
}

export type TillActions =
  | ListTills
  | ListTillsSuccess
  | ListTillSessions
  | ListTillSessionsSuccess
  | GetTillSessionById
  | GetTillSessionByIdSuccess
  | StartTillSession
  | StartTillSessionSuccess
  | CloseTillSession
  | CloseTillSessionSuccess
  | FetchExpectedClosingAmounts
  | FetchExpectedClosingAmountsSuccess
  | CreateTill
  | CreateTillSuccess
  | SetSelectedTillRange;
